import { mapOrders, mapOrder } from './../utils/helpers/orders';

import moment from 'moment';
/* eslint eqeqeq: "off" */

export default {
  orders: (state) => mapOrders(state.orders.filter((o) => o.type == '1')),
  getOrder: (state) => (id) =>
    mapOrder(state.orders.find((order) => order.id == id)),
  ordersTableLength: (state) => {
    return state.ordersTableLength;
  },
  ordersForDevolutionTableLength: (state) => {
    return state.ordersForDevolutionTableLength;
  },
  contingencyOrders: (state) => mapOrders(state.ordersContingency),
  getCashBoxOrders:
    (state) =>
    (cashBoxId, mask = true) => {
      const orders = state.orders.filter((o) => o.cashBoxId == cashBoxId);

      if (mask) return mapOrders(orders);

      return orders;
    },
  // includeProductsToFinancialTransaction: state => financialTransaction => {
  //   const order = mapOrders(state.orders.filter(order => order.id == financialTransaction.orderId))
  //   financialTransaction.products = order[0] == undefined ? order.orderProducts :  order[0].orderProducts
  //   return financialTransaction
  // },
  // allClientOrders: state => id => {
  //   return mapOrders(state.orders.filter(order => order.clientId == id))
  // },
  inProgress: (state) =>
    mapOrders(
      state.orders.filter(({ type, statusId }) => {
        return (
          type == '1' &&
          (statusId === 1 || statusId === 2 || statusId == 3 || statusId == 6)
        );
      })
    ),
  registered: (state) =>
    mapOrders(
      state.orders.filter(({ type, statusId }) => {
        return statusId === 1 && type == '1';
      })
    ),
  finished: (state) =>
    mapOrders(
      state.orders.filter(({ statusId, type }) => {
        return (statusId === 4 || statusId === 5) && type == '1';
      })
    ),
  finishedSalesForDevolution: (state) => {
    return mapOrders(
      state.ordersForDevolution.filter(({ type, id, statusId }) => {
        return statusId === 4 || statusId === 5;
      })
    );
  },
  sales: (state) =>
    mapOrders(
      state.orders.filter(({ type, id, statusId }) => {
        return statusId === 4 || statusId === 5;
      })
    ),
  finishedSales: (state) =>
    mapOrders(
      state.orders.filter(({ type, id, statusId }) => {
        return statusId === 4 || statusId === 5;
      })
    ),
  getSalesByFinancialTransactions:
    (state, getters, rootGetters) => (transactions) => {
      // console.log(state.orders)
      return state.orders;
      // let sales = []
      // transactions.map((transaction) => {
      //   getters.sales.filter((sale) => {
      //     if (transaction.order) {
      //       if (
      //         transaction.order.id == sale.id &&
      //         transaction.originBillId == null &&
      //         !sale.isTotalDevoluted
      //       ) {
      //         sales.push(sale);
      //       }
      //     }
      //   });
      // });
      // return sales
    },
  salesByClient: (state) => (idClient) => {
    return mapOrders(
      state.orders.filter(({ statusId, clientId }) => {
        return statusId === 4 && clientId == idClient;
      })
    );
  },
  // todaySales: state =>
  //   mapOrders(
  //     state.todaySales.filter(({ type, createdAt, id, statusId }) => {
  //       return (statusId === 4) && moment(createdAt).isSameOrAfter(moment().format("YYYY-MM-DD"));
  //     })
  //   ),
  nfceHistoric: (state) =>
    mapOrders(
      state.orders.filter(({ nfMod }) => {
        return nfMod == 65;
      })
    ),
  nfeHistoric: (state) => {
    return mapOrders(
      state.orders.filter((nf) => {
        return nf?.nfMod == 55 || nf?.isSketch;
      })
    );
  },
  // .sort((a, b) => {
  //   if(moment(a).isBefore(b)) return -1;
  //   else 1;
  // }),
  // finishedSales: state =>
  //   mapOrders(
  //     state.orders.filter(({ type, id, statusId }) => {
  //       return statusId === 4;
  //     })
  //   ),
  finishedSalesUnMask: (state) =>
    state.orders.filter(({ type, id, statusId }) => {
      return statusId === 4;
    }),
};
