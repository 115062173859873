import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  erro: undefined,
  ordersMessages: [],
  ordersContingency: 0,
  ordersTableLength: 0,
  orders: [],
  ordersForDevolution: [],
  ordersForDevolutionTableLength: 0,
  inutilizedInvoices: [],
  loading: true
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
