const Container = () => import('../views/Container');

const Index = () => import('../views/Index');

const NfeForm = () => import('../forms/NfeForm');

export default [
  {
    path: '/nota-fiscal',
    component: Container,
    meta: { requiresAuth: true, permissions: ['view_nfe'] },
    children: [
      {
        path: '',
        name: 'nfe-module',
        component: Index,
        props: true
      },
      // {
      //   path: '',
      //   name: 'nfe-form',
      //   component: NfeForm
      // },
    ]
  }
];
