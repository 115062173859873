export const EDIT_ORDER = 'EDIT_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const LIST_ORDERS = 'LIST_ORDERS';
export const LOAD_ORDER = 'LOAD_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const ADD_ARRAY_ORDERS = 'ADD_ARRAY_ORDERS';
export const SET_LOADING = 'SET_LOADING';
export const PLAY_NOTIFICATION = 'PLAY_NOTIFICATION';
export const STOP_NOTIFICATION = 'STOP_NOTIFICATION';
export const EDIT_ORDER_SKETCH = 'EDIT_ORDER_SKETCH'
export const ORDERS_CONTINGENCY = 'ORDERS_CONTINGENCY'
export const ADD_ORDERS_CONTINGENCY = 'ADD_ORDERS_CONTINGENCY'
export const EDIT_ORDERS_CONTINGENCY = 'EDIT_ORDERS_CONTINGENCY'
export const TABLES_LENGTH = 'TABLES_LENGTH'
//chat
export const NEW_MESSAGE = 'NEW_MESSAGE';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const SET_MESSAGE_AS_SEEN = 'SET_MESSAGE_AS_SEEN';
//inutilized invoices
export const LIST_INUTILIZED_INVOICES = 'LIST_INUTILIZED_INVOICES';
export const CREATE_INUTILIZED_INVOICE = 'CREATE_INUTILIZED_INVOICE';
// Types for orders that are ready for devolution
export const LIST_DEVOLUTION_ORDERS = 'LIST_DEVOLUTION_ORDERS';
export const DEVOLUTION_ORDERS_TABLE_LENGTH = 'DEVOLUTION_ORDERS_TABLE_LENGTH';